import { connectedBasicDeviceIds } from "../../enums/vitalSigns";
import { selectAvailableSensors } from "../../features/socket/mediotAgents";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { useSelector } from "react-redux";

interface IRangeInput {
  id?: string;
  minValue: number;
  maxValue: number;
  value: number;
  setValue: any;
  twoValues: boolean;
  onDecrement: () => void;
  onIncrement: () => void;
  measureName?: string;
  handleBars?: () => void;
  dualSensorsAvailability?: boolean;
  setSelectedDevice?: (value: string) => void;
  deviceId?: string;
  selectedDevice?: string | null;
  setStopDevice?: (value: boolean) => void;
}
const RangeInput = ({
  id,
  twoValues,
  minValue,
  maxValue,
  value,
  setValue,
  onDecrement,
  onIncrement,
  measureName,
  handleBars,
  setSelectedDevice,
  deviceId,
  selectedDevice,
  setStopDevice,
  dualSensorsAvailability,
}: IRangeInput) => {
  const handleChange = (event: any) => {
    if (event.target.value <= Number(minValue)) setValue(Number(minValue));
    else setValue(Number(event.target.value));
  };

  // selectors
  // const availableSensors = useSelector(selectAvailableSensors);

  return (
    <>
      <div className="grid grid-cols-3">
        <div className={`${twoValues ? "" : "col-span-2 mr-4"}`}>
          <input
            type="range"
            min={minValue}
            max={maxValue}
            onChange={handleChange}
            value={value == 0 ? minValue : value}
          />
        </div>
        {twoValues && (
          <div className="flex justify-evenly items-center">
            <FaAngleLeft onClick={handleBars} className="cursor-pointer" />
            {
            // id === connectedBasicDeviceIds.WEIGHT_HEIGHT ? (
            //   // availableSensors && availableSensors.includes(id) ? (
            //   dualSensorsAvailability ? (
            //     selectedDevice ? (
            //       <span
            //         className="bg-red-100 text-red-800 text-lg font-semibold px-10 p-1  rounded-full dark:bg-red-900 dark:text-red-300 cursor-pointer hover:bg-light-red-800 hover:text-white hover:font-bold"
            //         onClick={() => {
            //           setStopDevice && setStopDevice(true);
            //         }}
            //       >
            //         {measureName}
            //       </span>
            //     ) : (
            //       <span
            //         className="hover:bg-blue-100 hover:text-blue-800 text-lg hover:font-semibold px-10 p-1  rounded-full dark:bg-blue-900 dark:text-blue-300 cursor-pointer bg-light-blue-800 text-white font-bold"
            //         onClick={() => {
            //           setSelectedDevice && setSelectedDevice(deviceId!);
            //         }}
            //       >
            //         {measureName}
            //       </span>
            //     )
            //   ) : (
            //     <span className="bg-gray-100 text-gray-800 text-lg font-semibold px-10 p-1  rounded-full dark:bg-gray-900 dark:text-gray-300 cursor-not-allowed">
            //       {measureName}
            //     </span>
            //   )
            // ) :
             (
              <p className="font-semibold text-[#858585] text-center">
                {measureName}
              </p>
            )}

            <FaAngleRight onClick={handleBars} className="cursor-pointer" />
          </div>
        )}
        <div className="flex justify-end gap-2 items-center mt-[-7px]">
          <button className="rangBtnminus" onClick={onDecrement}>
            -
          </button>
          <button className="rangBtnplus" onClick={onIncrement}>
            +
          </button>
        </div>
      </div>
    </>
  );
};

export default RangeInput;
