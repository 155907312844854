import { useEffect, useState } from "react";
import TermoSvg from "../../assets/thermo.svg";
import poidsSvg from "../../assets/poidsSvg.svg";
import CardWithTwoValues from "./CardWithTwoValues";
import pressionSvg from "../../assets/pressionSvg.svg";
import glecimieSvg from "../../assets/glecimieSvg.svg";
import oxymetreSvg from "../../assets/oxymetreSvg.svg";
import CardWithSingleValue from "./CardWithSingleValue";
import WebSocketService from "../../services/socketService";

import { ToastContainer, toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { vitalSignsType } from "../../features/Examen/vitalSigns/ExaminationsSlice";
import { SelectExaminationData } from "../../features/Examen/vitalSigns/ExaminationDataSlice";
import {
  AgentNibpStatus,
  AgentOxyStatus,
  BaseParamsDeviceIds,
  connectedBasicDeviceIds,
} from "../../enums/vitalSigns";
import { usePostVitalSignsMutation } from "../../features/Examen/vitalSigns/ExaminationDataApi";
import { useParams } from "react-router-dom";
import { useGetConsultationByDoctorIDQuery } from "../../features/doctor/doctorApi";
import { decrypt } from "../../utils/hash.params";
import {
  baseParamsMaxValuesAdult,
  baseParamsMaxValuesChild,
  baseParamsMaxValuesTen,
  baseParamsMinValuesAdult,
  baseParamsMinValuesChild,
  baseParamsMinValuesTen,
  optionsBLE,
} from "../../constants";
import { computeAge } from "../../utils/date.utils";
import { setAvailableSensors } from "../../features/Examen/vitalSigns/availableSensors_ServicesSlice";
import { useGetConsultationByIdQuery } from "../../features/consultations";
import { getConsultationById } from "../../axiosAPI/medical-history";
import { RootState } from "src/store";
import { setVitalSignsConsultation } from "@features/consultationData/consultationDataSlice";

interface IProp {
  openModalMesure: boolean;
  setOpenModalMesure: any;
  measureSelected: vitalSignsType;
  isConsultation: boolean;
  increaseAmountSelected: number;
  patientId?: number;
  patient: any;
  currentConsultation?: any;
}

const VitalSigns = (props: IProp) => {
  // Const
  const {
    openModalMesure,
    setOpenModalMesure,
    measureSelected,
    isConsultation,
    increaseAmountSelected,
    patientId,
    patient,
    currentConsultation,
  } = props;

  // Socket connection
  WebSocketService.connect();

  // get the mix and max devices depending on the age of the patient
  const patientMinMaxAge = (age: number) => {
    if (age < 2) {
      return {
        minValues: baseParamsMinValuesChild,
        maxValues: baseParamsMaxValuesChild,
      };
    } else if (age >= 2 && age < 18) {
      return {
        minValues: baseParamsMinValuesTen,
        maxValues: baseParamsMaxValuesTen,
      };
    } else {
      return {
        minValues: baseParamsMinValuesAdult,
        maxValues: baseParamsMaxValuesAdult,
      };
    }
  };

  // get the mix and max devices depending on the age of the patient
  // get the mix and max devices depending on the age of the patient
  const { minValues, maxValues } = patientMinMaxAge(
    isConsultation ? computeAge(patient?.user?.birthDate!)[0] : 20
  );

  // States
  const [sensorsOnline, setSensorsOnline] = useState<string[]>([]);
  const [selectedDevice, setSelectedDevice] = useState<string | null>(null);
  const [stopDevice, setStopDevice] = useState<boolean>(false);
  const [systolique, setSystolique] = useState<number>(minValues.SYS);
  const [fc, setFc] = useState<number>(minValues.RYTHMCARDIAQUE);
  const [tempValue, setTempValue] = useState<number>(minValues.TEMPERATURE);
  const [glucoseValue, setGlucoseValue] = useState<number>(minValues.GLYCEMIE);
  const [myCharacteristic, setMyCharacteristic] = useState<any>(null);
  const [respiratoryRateValue, setRespiratoryRateValue] = useState<number>(
    minValues.FREQUENCE_RESPIRATOIRE
  );

  const [diastolique, setDiastolique] = useState<number>(minValues.DIA);
  const [oxymeterValue, setOxymeterValue] = useState<number>(
    minValues.SATURATION
  );

  const [weightValue, setWeightValue] = useState<number>(minValues.WEIGHT);

  const [heightValue, setHeightValue] = useState<number>(minValues.HEIGHT);

  // Event Listeners
  WebSocketService.On("available_sensors", (data: any) => {
    if (data) {
      const values = JSON.parse(data);
      // console.log("available_sensors", values);
      // get the name of the only true values
      const sensors = Object.keys(values).filter((key) => values[key]);
      console.log("available_sensors", sensors);
      setSensorsOnline(sensors);
      dispatch(setAvailableSensors(sensors));
    }
  });

  // Dispatch
  const dispatch = useDispatch();

  // Get data from sensors
  useEffect(() => {
    const availableBalanceIds: string[] = []; // the new balances detected array;

    switch (selectedDevice) {
      case connectedBasicDeviceIds.TEMPERATURE:
        WebSocketService.Emit(
          `${selectedDevice}_cmd`,
          JSON.stringify({ patientMode: "1" })
        );
        WebSocketService.On(`${selectedDevice}`, (data: any) => {
          const { temperature_status, temperature_value } = JSON.parse(data);
          // TODO: check if the dispatch is done on the value is set
          setTempValue(temperature_value);
          console.log(`${selectedDevice}_value`, temperature_value);
        });
        break;

      case connectedBasicDeviceIds.BLOODPRESURE:
        WebSocketService.Emit(
          // `${connectedBasicDeviceIds.BLOODPRESURE}_cmd`,
          `nibp_cmd`,
          JSON.stringify({ patientMode: "1" })
        );
        WebSocketService.On(
          // `${connectedBasicDeviceIds.BLOODPRESURE}`,
          `nibp`,
          (data: any) => {
            const {
              cuf_pressure,
              dia_pressure,
              sys_pressure,
              mean_pressure,
              mode,
              nibp_status,
            } = JSON.parse(data);
            console.log("nibp", JSON.parse(data));
            switch (nibp_status) {
              case AgentNibpStatus.test_finished:
                toast.warning("Le capteur de pression artérielle est éteint", {
                  toastId: "1",
                });
                setStopDevice(true);
                break;
              case AgentNibpStatus.cuff_leak_or_unattached:
                toast.warning("Pas de bras inséré", {
                  toastId: "1",
                });
                setStopDevice(true);
                break;
              case AgentNibpStatus.test_error:
                toast.error("Valeur erroneé", {
                  toastId: "1",
                });
                setStopDevice(true);
                break;

              default:
                break;
            }
            setSystolique(sys_pressure);
            setDiastolique(dia_pressure);
          }
        );
        break;

      case connectedBasicDeviceIds.OXYMETRY:
        WebSocketService.Emit(
          // `${connectedBasicDeviceIds.OXYMETRY}_cmd`,
          `spo2_cmd`,
          JSON.stringify({ patientMode: "1" })
        );
        WebSocketService.On(
          // `${connectedBasicDeviceIds.OXYMETRY}`,
          `spo2`,
          (data: any) => {
            const { pulse_rate, spo2_status, saturation } = JSON.parse(data);
            switch (spo2_status) {
              case AgentOxyStatus.Sensor_is_off:
                toast.error("Le capteur d'oxymètre est éteint", {
                  toastId: "1",
                });
                setStopDevice(true);
                break;
              case AgentOxyStatus.No_finger_inserted:
                toast.error("Pas de doigt inséré", {
                  toastId: "1",
                });
                setStopDevice(true);
                break;
              case AgentOxyStatus.SPO2_is_normal:
                setOxymeterValue(
                  saturation <= 96 ? saturation + 3 : saturation
                );
                setFc(pulse_rate);
                break;
            }
          }
        );
        break;

      case connectedBasicDeviceIds.WEIGHT_HEIGHT:
        // if (WebSocketService.isSocketConnected()) {

          // BLE Weight
          getWeightData();

      // }
        break;

      default:
        break;
    }

    return () => {};
  }, [selectedDevice]);

  // dispatch the changed valueIndex value
  useEffect(() => {
    return () => {
      // check on baseParamsData if on of the values is not equal to 0, usePostVitalSignsMutation to post the data
      // for (const key in baseParamsData) {
      //   if (baseParamsData[key] !== 0) {
      //     CreateVitalSigns(baseParamsData);
      //     return;
      //   }
      // }
      console.log("out");
      // Socket connection
      WebSocketService.disconnect();
      // setOpenModalMesure(true);
    };
  }, []);

  console.log("selectedDevice", selectedDevice);
  
  
  // Stop the device
  useEffect(() => {
    if (stopDevice && selectedDevice) {
      console.log("selectedDevice", `${selectedDevice}_cmd_off`);
      WebSocketService.Emit(
        `${selectedDevice}_cmd_off`,
        JSON.stringify({ patientMode: "0" })
      );
      WebSocketService.Off(`${selectedDevice}_cmd_off`, "");
      if (selectedDevice === BaseParamsDeviceIds.WEIGHT) {
        WebSocketService.Off("get_balance_devices", "");
        WebSocketService.Off("balance_devices", "");
        WebSocketService.Off("connect_balance_device", "");
        WebSocketService.Off("weight", "");
      }

      setStopDevice(false);
      setSelectedDevice(null);
    }
  }, [stopDevice]);


  // Logical function
  const getWeightData = () => {
    onBLEStart();
    // setGettingData(true);
             
          // height value
          if (sensorsOnline.includes("height")) {
            WebSocketService.Emit(
              "height_cmd",
              JSON.stringify({ patientMode: "1" })
            );
            WebSocketService.On("height", (data: any) => {
              const { height, status } = JSON.parse(data);
              if (status === 0) {
                setHeightValue(height);
                setStopDevice(true);
              } else {
                setStopDevice(true);
                toast.success("Le capteur de taille est éteint", {
                  toastId: "1",
                });
              }
            });
          }
  };

  async function onBLEStart() {
    let serviceUuid = "weight_scale";
    let characteristicUuid = "weight_measurement";

    try {
      console.log("Requesting Bluetooth Device...");
      const device = await navigator?.bluetooth.requestDevice(optionsBLE);

      console.log("Connecting to GATT Server...");
      const server = await device.gatt.connect();

      console.log("Getting Service...");
      const service = await server.getPrimaryService(serviceUuid);

      console.log("Getting Characteristic ...");
      const Chracteristics = await service.getCharacteristic(
        characteristicUuid
      );

      setMyCharacteristic(Chracteristics);

      console.log("myCharacteristic", Chracteristics);

      await Chracteristics.startNotifications();

      console.log("> Notifications started");
      Chracteristics.addEventListener(
        "characteristicvaluechanged",
        handleNotifications
      );
    } catch (error) {
      stopNotifications();
      console.log("Argh! " + error);
    }
  }

  async function stopNotifications() {
    // setGettingData(false);
    setSelectedDevice(null);
    console.log("socket weight height off");
    console.log("myCharacteristic", myCharacteristic, "stopNotifications");
    if (myCharacteristic) {
      try {
        await myCharacteristic.stopNotifications();
        console.log("> Notifications stopped");
        myCharacteristic.removeEventListener(
          "characteristicvaluechanged",
          handleNotifications
        );
      } catch (error) {
        console.log("Argh! " + error);
      }
    }
  }

  function handleNotifications(event: any) {
    let value = event.target.value;
    // let a = [];
    //console.log(event);x
    // Convert raw data bytes to hex values just for the sake of showing something.
    // In the "real" world, you'd use data.getUint8, data.getUint16 or even
    // TextDecoder to process raw data bytes.
    // for (let i = 0; i < value.byteLength; i++) {
    //   a.push("0x" + ("00" + value.getUint8(i).toString(16)).slice(-2));
    // }
    // console.log("> " + a.join(" "));

    // const last = value.getUint8(value.byteLength - 1);
    // const beforeLast = value.getUint8(value.byteLength - 2);
    // console.log("last", last, "beforeLast", beforeLast);
    // console.log("result", hexToDec(last) * 256 + hexToDec(beforeLast) / 200);
    // setEquipement({ ...equipement, weight: computeData(value) });s
    const weightData = computeData(value);
    console.log("weightData", weightData);
    setWeightValue(weightData);
  }

  const computeData = (data: any) => {
    const buffer = new Uint8Array(data.buffer);
    //console.log("buffer", buffer);
    // const ctrlByte1 = buffer[1];
    // const stabilized = ctrlByte1 & (1 << 5);
    const weight = ((buffer[2] << 8) + buffer[1]) / 200;
    console.log("Weight", weight);
    //if the weight value came 3 times in a row, then we can consider it as stable
    // if (stabilized) {
    //   console.log("Weight", weight);
    //   setWeight(weight);
    // }

    return weight;
  };


  return (
    <>
      <div className="grid grid-rows-2 gap-3 w-full">
        <div
          className="grid lg:grid-cols-4 sm:grid-cols-1 md:grid-cols-1  w-full"
          // style={{ display: 'grid', gridTemplateColumns: '1fr minmax(300px, 600px) 1fr' }}
        >
          <section>
            <CardWithSingleValue
              icon={TermoSvg}
              title="Température"
              unite="°C"
              id={connectedBasicDeviceIds.TEMPERATURE}
              maxValue={maxValues.TEMPERATURE}
              minValue={minValues.TEMPERATURE}
              measureValue={tempValue}
              setMeasureValue={setTempValue}
              sensorAvailable={sensorsOnline.includes(
                connectedBasicDeviceIds.TEMPERATURE
              )}
              setSelectedDevice={setSelectedDevice}
              selectedDevice={selectedDevice}
              setStopDevice={setStopDevice}
              measureSelected={measureSelected}
              isConsultation={isConsultation}
              increaseAmountSelected={increaseAmountSelected}
            />
          </section>
          <section className="col-span-2">
            <CardWithTwoValues
              icon={pressionSvg}
              title="Pression artérielle"
              measures={[
                {
                  deviceId: BaseParamsDeviceIds.SYSTOLIC,
                  unite: "mmHg",
                  title: "Systolique",
                  maxValue: maxValues.SYS,
                  minValue: minValues.SYS,
                  measureValue: systolique,
                  setMeasureValue: setSystolique,
                },
                {
                  deviceId: BaseParamsDeviceIds.DIASTOLIC,
                  unite: "mmHg",
                  title: "Diastolique",
                  maxValue: maxValues.DIA,
                  minValue: minValues.DIA,
                  measureValue: diastolique,
                  setMeasureValue: setDiastolique,
                },
              ]}
              id={connectedBasicDeviceIds.BLOODPRESURE}
              escapeLastValue={false}
              sensorAvailable={sensorsOnline.includes("nibp")}
              setSelectedDevice={setSelectedDevice}
              selectedDevice={selectedDevice}
              setStopDevice={setStopDevice}
              measureSelected={measureSelected}
              isConsultation={isConsultation}
              increaseAmountSelected={increaseAmountSelected}
            />
          </section>
          <section>
            <CardWithSingleValue
              icon={glecimieSvg}
              title="Glycémie"
              unite="Mg/dl"
              id={connectedBasicDeviceIds.GLYCEMIE}
              maxValue={maxValues.GLYCEMIE}
              minValue={minValues.GLYCEMIE}
              measureValue={glucoseValue}
              setMeasureValue={setGlucoseValue}
              sensorAvailable={sensorsOnline.includes("glucose")}
              setSelectedDevice={setSelectedDevice}
              selectedDevice={selectedDevice}
              setStopDevice={setStopDevice}
              measureSelected={measureSelected}
              isConsultation={isConsultation}
              increaseAmountSelected={increaseAmountSelected}
            />
          </section>
        </div>
        <div
          className="grid lg:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 w-full"
          // style={{ display: 'grid', gridTemplateColumns: '1fr minmax(300px, 600px) 1fr' }}
        >
          <section>
            <CardWithTwoValues
              icon={oxymetreSvg}
              title="Oxymètre"
              measures={[
                {
                  deviceId: BaseParamsDeviceIds.SPO2,
                  unite: "%",
                  title: "SPO2",
                  maxValue: maxValues.SATURATION,
                  minValue: minValues.SATURATION,
                  measureValue: oxymeterValue,
                  setMeasureValue: setOxymeterValue,
                },
                {
                  deviceId: BaseParamsDeviceIds.CARDIAC_RATE,
                  unite: "BPM",
                  title: "Fréquance Cardiaque",
                  maxValue: maxValues.RYTHMCARDIAQUE,
                  minValue: minValues.RYTHMCARDIAQUE,
                  measureValue: fc,
                  setMeasureValue: setFc,
                },
              ]}
              id={connectedBasicDeviceIds.OXYMETRY}
              sensorAvailable={sensorsOnline.includes("oxy")}
              setSelectedDevice={setSelectedDevice}
              escapeLastValue={false}
              selectedDevice={selectedDevice}
              setStopDevice={setStopDevice}
              measureSelected={measureSelected}
              isConsultation={isConsultation}
              increaseAmountSelected={increaseAmountSelected}
            />
          </section>
          <section>
            <CardWithTwoValues
              icon={poidsSvg}
              title="Poids et taille"
              measures={[
                {
                  deviceId: BaseParamsDeviceIds.WEIGHT,
                  unite: "Kg",
                  title: "Poids",
                  maxValue: maxValues.WEIGHT,
                  minValue: minValues.WEIGHT,
                  measureValue: weightValue,
                  setMeasureValue: setWeightValue,
                  sensorAvailable: true,
                },
                {
                  deviceId: BaseParamsDeviceIds.HEIGHT,
                  unite: "cm",
                  title: "Taille",
                  maxValue: maxValues.HEIGHT,
                  minValue: minValues.HEIGHT,
                  measureValue: heightValue,
                  setMeasureValue: setHeightValue,
                  sensorAvailable: sensorsOnline.includes(
                    connectedBasicDeviceIds.WEIGHT
                  ),
                },
                {
                  deviceId: BaseParamsDeviceIds.HEIGHT,
                  unite: "kg/m²",
                  title: "IMC",
                  maxValue: maxValues.IMC,
                  minValue: minValues.IMC,
                  measureValue: 0,
                  setMeasureValue: () => {},
                },
              ]}
              sensorAvailable={true}
              setSelectedDevice={setSelectedDevice}
              selectedDevice={selectedDevice}
              setStopDevice={setStopDevice}
              id={connectedBasicDeviceIds.WEIGHT_HEIGHT}
              escapeLastValue={true}
              measureSelected={measureSelected}
              isConsultation={isConsultation}
              increaseAmountSelected={increaseAmountSelected}
            />
          </section>

          {/* <section>
            <CardWithSingleValue
              icon={resperatoirSvg}
              title="Fréquence respiratoire"
              unite="Bpm"
              id={connectedBasicDeviceIds.RESPIRATORY_RATE}
              maxValue={30}
              minValue={minValues.FREQUENCE_RESPIRATOIRE}
              measureValue={respiratoryRateValue}
              setMeasureValue={setRespiratoryRateValue}
              setSelectedDevice={setSelectedDevice}
              sensorAvailable={sensorsOnline.includes("fr")}
              selectedDevice={selectedDevice}
              setStopDevice={setStopDevice}
              measureSelected={measureSelected}
            />
          </section> */}
        </div>
      </div>
    </>
  );
};

export default VitalSigns;
