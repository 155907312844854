// HERE WE DEFINE ALL APP CONSTANTS
import PeriDeBase from "./assets/periDeBase.svg";
import EcgSvg from "./assets/ecgSvg.svg";
import DermatoscopeSvg from "./assets/dermaSvg.svg";
import OtoscopeSvg from "./assets/otoscopeSvg.svg";
import IriScope from "./assets/iriscope.svg";
import EchographSvg from "./assets/echoSvg.svg";
import CameraSvg from "./assets/cameraSvg.svg";
import { ExeName } from "./enums/vitalSigns";
import ThermoSvg from "./assets/thermo.svg";
import PressionSvg from "./assets/pressionSvg.svg";
import OxymetreSvg from "./assets/oxymetreSvg.svg";
import PoidsSvg from "./assets/poidsSvg.svg";
import GlecimieSvg from "./assets/glecimieSvg.svg";
import HeartSvg from "./assets/hearSvg.svg";
import RespiratoirSvg from "./assets/resperatoirSvg.svg";
import Stethoscope from "./assets/Stethoscope.svg";
import NoStreamSvg from "./assets/noStreamSvg.svg";
import ArrRight from "./assets/arrowRight.svg";
import { advancedMeasuresToolsIds, advancedMeasuresToolsNames } from "./enums/advancedParams";

const APP_CONSTANTS = {
  LOCALSTORAGE_PREFIX: "vs_",
  ROUTES: {
    HOME: "/",
    LOGIN: "/login",
    FORGOT_PASSWORD: "/forgotPassword",
    PATIENTS_LIST: "/doctor/searchPatient",
    WAITING_LIST: "/doctor/waitingList",
    ADD_PATIENT: "/doctor/addPatient",
    ADD_DOCTOR: "/doctor/addDoctor",
    STATISTICS: "/doctor/statistics",
    EXAMINATION: "/doctor/steps/:id/:consultationId",
    CALENDAR: "/doctor/calendar",
    DEVICES: "/doctor/devices",
    BASIC_DEVICES: "/doctor/basicDevices",
    ADVANCED_DEVICES: "/doctor/advancedDevices",
    DEVICES_WITHOUT_AUTHENTICATION: "/no-auth/devices",
    BASIC_DEVICES_WITHOUT_AUTHENTICATION: "/no-auth/basicDevices",
    ADVANCED_DEVICES_WITHOUT_AUTHENTICATION: "/no-auth/advancedDevices",

    CALL: "/doctor/call/:id",

    // SPECIALISTE ROUTES
    CALLSPECIALIST: "/specialiste/doctor/call/:id",
    CALL_HISTORY: "/specialiste/doctor",
    SPECIALISR_CALENDAR: "/specialiste/doctor/calendar",
    SPECIALIST_AVAILABILITY: "/specialiste/doctor/availability",
    SPECIALIST_RDV_TABLE: "/specialiste/doctor/rdv-table",
    SPECIALIST_DOCTOR_HISTORIQUE: "/specialiste/doctor/historique",
  },
};

// min values
export const baseParamsMinValuesChild = {
  // 1-2 years
  TEMPERATURE: 30.0, //
  SATURATION: 80, //
  SYS: 80, //
  DIA: 40, //
  GLYCEMIE: 0.3, //
  RYTHMCARDIAQUE: 45, //
  FREQUENCE_RESPIRATOIRE: 10, //
  WEIGHT: 2.0,
  HEIGHT: 40.0,
  IMC: 40,
};

export const baseParamsMinValuesTen = {
  // 2-18 years
  TEMPERATURE: 30.0,
  SATURATION: 80,
  SYS: 80,
  DIA: 40,
  GLYCEMIE: 0.3,
  RYTHMCARDIAQUE: 45,
  FREQUENCE_RESPIRATOIRE: 10,
  WEIGHT: 8.0,
  HEIGHT: 60.0,
  IMC: 40,
};

export const baseParamsMinValuesAdult = {
  // > 18 years
  TEMPERATURE: 30.0,
  SATURATION: 80,
  SYS: 80,
  DIA: 40,
  GLYCEMIE: 0.3,
  RYTHMCARDIAQUE: 45,
  FREQUENCE_RESPIRATOIRE: 10,
  WEIGHT: 40.0,
  HEIGHT: 140.0,
  IMC: 40,
};

// max values
export const baseParamsMaxValuesChild = {
  // 1-2 years
  TEMPERATURE: 47.0,
  SATURATION: 100,
  SYS: 240,
  DIA: 120,
  GLYCEMIE: 7,
  RYTHMCARDIAQUE: 220,
  FREQUENCE_RESPIRATOIRE: 60,
  WEIGHT: 16.0,
  HEIGHT: 100.0,
  IMC: 100,
};

export const baseParamsMaxValuesTen = {
  // 2-18 years
  TEMPERATURE: 47.0,
  SATURATION: 100,
  SYS: 240,
  DIA: 120,
  GLYCEMIE: 7,
  RYTHMCARDIAQUE: 220,
  FREQUENCE_RESPIRATOIRE: 60,
  WEIGHT: 100.0,
  HEIGHT: 220.0,
  IMC: 100,
};

export const baseParamsMaxValuesAdult = {
  // > 18 years
  TEMPERATURE: 47.0,
  SATURATION: 100,
  SYS: 240,
  DIA: 120,
  GLYCEMIE: 7,
  RYTHMCARDIAQUE: 220,
  FREQUENCE_RESPIRATOIRE: 60,
  WEIGHT: 190.0,
  HEIGHT: 220.0,
  IMC: 100,
};

export const videoCallDevices = [
  {
    displayedName: "ECG",
    icon: EcgSvg,
    name: "ecg",
  },
  {
    displayedName: "Dermatoscope",
    icon: DermatoscopeSvg,
    name: "dermatoscope",
  },
  {
    displayedName: "Otoscope",
    icon: OtoscopeSvg,
    name: "otoScope",
  },
  {
    displayedName: "iriscope",
    icon: IriScope,
    name: "iriscope",
  },
  {
    displayedName: "Caméra mobile",
    icon: CameraSvg,
    name: "cameraMobile",
  },
  {
    displayedName: "Échographe",
    icon: EchographSvg,
    name: ExeName.ECHO,
  },

  {
    displayedName: "Tension artérielle",
    icon: PressionSvg,
    name: "nibp",
  },
  {
    displayedName: "Saturation O2",
    icon: OxymetreSvg,
    name: "oxy",
  },
  // {
  //   displayedName: "Poids et taille",
  //   icon: PoidsSvg,
  //   name: "weight_height",
  // },
  // {
  //   displayedName: "Glycémie",
  //   icon: GlecimieSvg,
  //   name: "glycemi",
  // },
  {
    displayedName: "Rythme cardiaque",
    icon: HeartSvg,
    name: "rc",
  },
  {
    displayedName: "Fréquence respiratoire",
    icon: RespiratoirSvg,
    name: "rr",
  },
  {
    displayedName: "Stethoscope",
    icon: Stethoscope,
    name: "styto",
  },
];


export const AdvancedParamsTools = [
  {
    ToolsId: advancedMeasuresToolsIds.ECG,
    name: advancedMeasuresToolsNames.ECG,
    icon: EcgSvg,
  },
  {
    ToolsId: advancedMeasuresToolsIds.DERMATOSCOPE,
    name: advancedMeasuresToolsNames.DERMATOSCOPE,
    icon: DermatoscopeSvg,
  },
  {
    ToolsId: advancedMeasuresToolsIds.OTOSCOPE,
    name: advancedMeasuresToolsNames.OTOSCOPE,
    icon: OtoscopeSvg,
  },
  {
    ToolsId: advancedMeasuresToolsIds.IRISCOPE,
    name: advancedMeasuresToolsNames.IRISCOPE,
    icon: IriScope,
  },

  {
    ToolsId: advancedMeasuresToolsIds.ECHOGRAPHIE,
    name: advancedMeasuresToolsNames.ECHOGRAPHIE,
    icon: EchographSvg,
  },
  {
    ToolsId: advancedMeasuresToolsIds.MOBILE_CAMERA,
    name: advancedMeasuresToolsNames.MOBILE_CAMERA,
    icon: CameraSvg,
  },
];


// medicalDevices.ts (or any other appropriate file)
export const MEDICAL_DEVICES = {
  WEIGHT_AND_HEIGHT: "Weight and Height",
  TEMPERATURE: "Temperature",
  OXYMETER: "Oximeter",
  BLOOD_PRESSURE: "Blood Pressure Monitor",
  BODY_COMPOSITION: "Body Composition Analyzer",
  HEART_RATE_MONITOR: "Heart Rate Monitor",
  RESPIRATORY_RATE_MONITOR: "Respiratory Rate Monitor",
  DERMATOSCOPE: "Dermatoscope",
  OTOSCOPE: "Otoscope",
  ECG: "Electrocardiogram (ECG)",
  ULTRASOUND: "Ultrasound Machine",
  MOBILE_CAMERA: "Mobile Camera",
};
export const TABLE_HEAD = ["Device Name", "", ""];

export const PATIENT_RECORD_TABS = {
  PATIENT_FILE: 0,
  MEDICAL_HISTORY: 1,
  VITAL_SIGNS: 2,
  BIOLOGY: 3,
  REPORTS: 4,
};

export const AppUserId = Number(
  localStorage.getItem(`${APP_CONSTANTS.LOCALSTORAGE_PREFIX}userId`)
);
export default APP_CONSTANTS;


// BLUETOOTH DEVICES SELECT OPTIONS
export const optionsBLE = {
  // filters: [{ services: [serviceUuid] }],
  filters: [{ services: ["weight_scale"] }, { namePrefix: "MI_SCALE" }],
};