import React, { useEffect } from "react";
import Nav from "../Nav";
import LeftNav from "./LeftSidebar";
import RightNav from "./RightSidebar";
import MainContent from "./MainContent";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import WebSocketService from "../../services/Notification/socketService";
import { Outlet } from "react-router";

export function MyLayout() {
  
  // const rightIsOpen = useSelector((state: RootState) => state.sidebar.rightIsOpen)
  const leftIsOpen = useSelector((state: RootState) => state.sidebar.leftIsOpen)
  const currentCallId = useSelector((state: RootState) => state.auth.currentCallId)

  WebSocketService.connect();


  useEffect(()=> {
    console.log("current call is", currentCallId)
  }, [])

  return (
    <>
      <Nav />
      <div className="flex">
        <div className={`${leftIsOpen ? "w-72" : "w-0"} duration-300 h-screen relative`}>
          <LeftNav />
        </div>

        <div className="px-6 py-1 flex-1 mt-16" style={{height:"calc(100vh - 20px)"}}>
          <Outlet />
        </div>
      </div>



    </>
  );
}

export default MyLayout;
