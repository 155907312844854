import { io } from "socket.io-client";
// import { Howl } from "howler";
// import notifSound from "../../assets/notifSound.wav";
// import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
// import { useState } from "react";
import { off } from "process";

const WebSocketService = (() => {
  // States
  let socket: any = null;

  //   const sound = new Howl({
  //     src: [notifSound],
  //   });



  const connect = () => {
    if (!socket) {
      socket = io(
        `${process.env.REACT_APP_MEDIOT_AGENT_URL}`
        //   {
        //     // auth: { token: localStorage.getItem("vs_token") },
        //     transports: ["websocket"],
        //   }
      );

      socket.on("connect", () => {
        console.log("Socket.IO connection established.");
      });

      socket.on("connect_error", (error: any) => {
        // console.warn("Socket.IO connection error:", error);
      });

      socket.on("disconnect", (reason: any) => {
        // console.log("Socket.IO disconnected:", reason);
      });

      // socket.on("available_sensors", (data) => {
      //   const values = JSON.parse(data);
      //   // get the name of the only true values
      //   const sensors = Object.keys(values).filter((key) => values[key]);
      //   console.log("available_sensors", sensors);
      //   // setAvailableSensors(sensors);
      // });
    }
  };

  const Emit = (event: string, data: any) => {
    return new Promise<void>((resolve, reject) => {
      if (!socket) return reject("No socket connection.");
      return socket.emit(event, data, (response: any) => {
        if (response.error) {
          console.warn(response.error);
          return reject(response.error);
        }
        console.warn(`emitevent [ ${event} ] sent`);
        return resolve();
      });
    });
  };

  const timeOutemit = (event: string, data: any) => {
    return new Promise<void>((resolve, reject) => {
      if (!socket) return reject("No socket connection.");
      return socket.timeout(5000).emit(event, data, (response: any) => {
        if (response.error) {
          console.warn(response.error);
          return reject(response.error);
        }
        console.warn(`emitevent [ ${event} ] sent`);
        return resolve();
      });
    });
  };

  const On = (event: string, func: any) => {
    return new Promise<void>((resolve, reject) => {
      if (!socket) return reject("No socket connection.");
      socket.on(event, func);
      resolve();
    });
  };

  const Off = (event: string, func: any) => {
    return new Promise<void>((resolve, reject) => {
      if (!socket) return reject("No socket connection.");
      socket.off(event, func);
      resolve();
    });
  };

  // disconnect from socket
  const disconnect = () => {
    if (socket) {
      socket.disconnect();
      socket = null;
    }
  };


  const isSocketConnected = () => {
    return socket?.connected;
  }

  // todo :Compagnion message sending for ECG 12 derivations
  /**
   *
   */
  //   const showScreen = (appName: ExeName) => {
  //     console.log("conpagnionAgent", conpagnionAgent);

  //     if (conpagnionAgent?.OPEN) {
  //       try {
  //         conpagnionAgent.send(
  //           JSON.stringify({
  //             messageType: "command",
  //             command: {
  //               commandType: "start", /// start | stop
  //               // commandType: "show", /// start | stop | show
  //               appName: appName,
  //             },
  //           })
  //         );
  //         toast.success("ECG est bien lancé");
  //       } catch (error) {
  //         console.log("conpagnionAgent error", error);
  //       }
  //     } else {
  //       // toast.warning("Veuillez exécuter VisioStationCompagnion");
  //     }
  //   };

  //   /**
  //    *
  //    */
  //   const exitApp = (appName: ExeName) => {
  //     if (conpagnionAgent?.OPEN) {
  //       conpagnionAgent.send(
  //         JSON.stringify({
  //           messageType: "command",
  //           command: {
  //             commandType: "stop", /// start | stop
  //             appName: appName,
  //             // }
  //           },
  //         })
  //       );
  //     } else {
  //       // toast.warning("Veuillez exécuter VisioStationCompagnion");
  //     }
  //   };

  /**
   *
   * @param callback
   */
  //   const subscribeToNotifications = (callback: (data: any) => void) => {
  //     if (socket) {
  //       socket.on("new-notification", (data: any) => {
  //         console.log("socket data", data);
  //         // sound.play();

  //         console.log("Received event: new-notification", data);
  //         callback(data);
  //       });
  //     }
  //   };

  //   const sendBaseParamsCallData = (payload: any) => {
  //     console.log("values to send", payload);

  //     socket.emit("sendDataTool", payload);
  //   };

  return {
    // nibpCallBackStatus,
    // oxymeterCallBackStatus,
    // availableSensors,
    connect,
    Emit,
    timeOutemit,
    Off,
    On,
    disconnect,
    isSocketConnected,
  };
})();

export default WebSocketService;
